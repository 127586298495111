@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500&family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css');

@font-face {
  font-family: "Sequel";
  src: url("assets/fonts/Sequel.ttf");
}

@font-face {
  font-family: "Calibri";
  src: url("assets/fonts/calibri-regular.ttf");
}

@font-face {
  font-family: "Calibri";
  src: url("assets/fonts/calibri-bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/fonts/calibri-italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "Calibri";
  src: url("assets/fonts/calibri-bold-italic.ttf");
  font-weight: bold;
  font-style: italic;
}


:root {
  --primary-color: #2cacdb;
  --dark-primary-color: #1a6783;
  --dark-color: #6e6b7b;
  --light-color: #ffffff;
  --grey-color: #cccccc;
  --orange-color: #ffa740;
  --blue-color: #0d4491;
  --green-color: #137c55;
  --red-color: #ff4d68;
  --danger-color: #ff4d68;
  font-size: 1rem;
}

body {
  margin: 0;

  font-family: "Calibri", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400;
  color: var(--dark-color) !important;
  font-style: normal;
  overflow: hidden;
  line-height: 1.45;
}

.container-fluid {
  background-color: var(--light-color);
}

.app-content {
  height: calc(100vh - 50px);
  overflow: hidden;
}

.subTitle-container {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.subTitle {
  padding-bottom: 10px !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #282561;
  cursor: pointer;
  position: relative;
  flex: 0 0 auto;
  white-space: nowrap;
  cursor: pointer;
}

.subTitle:hover {
  text-decoration: none;
}

.subTitle.active::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #59AFEC;
}

.subTitle-license {
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: black;
  cursor: pointer;
  position: relative;
  background-color: #ededef;
  border-radius: 5px;
  width: 20%;
  height: 90px;
  border-left: 8px solid #DC8612;
}

.subTitle-license p {
  padding-top: 15px;
  margin-left: 10px;
}

.subTitle-license:hover {
  text-decoration: none;
}

.subTitle-license.active::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #59AFEC;
}

.detail-txt {
  font-size: 32px;
  font-weight: 700;
  font-family: "Roboto";
  color: #2b83b5;
}

.container-license {
  display: flex;
  gap: 80px;
}

.container-license p,
.total-license {
  margin: 0;
}

.container-license>div {
  box-sizing: border-box;
}

.container-license p span {
  color: black;
}

.add-user {
  display: block;
  color: black;
  display: inline-block;
  border: 0px solid #d9eff7;
  background-color: #d9eff7;
  color: black;
  padding: 5px 10px;
  border-radius: 0 0 0 0;
}

.container-license button {
  border: 1px solid #d9eff7;
  background-color: #d9eff7;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  width: 30px;
}

.container-license button:hover {
  background-color: #bcdde7;
}

.container-license button:focus,
.container-license button:active {
  outline: none;
}

.monthly-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.monthly-item {
  flex: 1;
  margin-right: 18px;
}

.monthly-item:last-child {
  margin-right: 0;
}

.monthly-payment h2 {
  font-size: 26px;
  font-weight: 500;
  font-family: "Roboto";
  color: black;
}

.total-amount {
  font-weight: bold;
  font-size: 35px;
  color: #DC8612;
  display: flex;
  align-items: baseline;
}

.total-amount p {
  font-size: 15px;
  color: #31363F;
  margin-left: 8px;
}

.subTitle-type {
  padding-bottom: 10px !important;
  font-weight: 600 !important;
  font-size: 1.1rem !important;
  color: #282561;
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.subTitle-type.active {
  background-color: #3498db;
  color: white;
}

.subTitle-type:hover:not(.active) {
  background-color: #70c1ff;
  color: white;
}

.container-type-license {
  display: flex;
  align-items: center;
}

.container-detail {
  background-color: #e1f4fb;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.type-license {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 120px;
}

.color-primary {
  color: var(--primary-color);
}

a:hover {
  text-decoration: none;
}

/* Forms */
.form-control {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.4rem 1rem !important;
  height: calc(1em + 1.3rem) !important;
}

.form-cash {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem !important;
  line-height: 1.5;
  width: 100%;
  padding-left: 46px;
  border: 0.8px solid;
  border-radius: 10px;
  opacity: 0.8;
  height: calc(1em + 1.3rem) !important;
}

.form-control::placeholder,
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: rgba(113, 121, 134, 0.75) !important;
  opacity: 1;
}

.form-group {
  margin-bottom: 11px !important;
}

.form-group .label {
  color: var(--dark-color);
  font-size: .85rem;
  margin-bottom: 0.1rem !important;
}

.form-group .required-star {
  color: var(--danger-color);
}

.date-range {
  display: flex;
  padding: 0.4rem 1rem !important;
  font-weight: 400;
  font-size: 0.8rem !important;
  border: 1px solid var(--grey-color);
  border-radius: 0.25rem;
  background: var(--light-color);
  align-items: center;
  cursor: pointer;
  height: calc(1em + 1.3rem) !important;
  line-height: 1.5;
}

.date-range>span {
  padding-left: 5px !important;
  padding-right: 5px;
  font-size: 0.8rem !important;
  color: #9c9c9c;
  font-weight: 500;
}

.date-range-arrow {
  position: absolute;
  right: 25px;
}

.date-container {
  top: 40px;
  left: auto;
  right: 0px;
  display: block;
  z-index: 9999 !important;

  position: absolute;
  border-radius: 4px;
  border: 1px solid var(--grey-color);
  padding: 0;
  margin: 0px;
  margin-top: 7px;
  background-color: var(--light-color);
}

.date-container:before {
  left: 360px;

  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;

  position: absolute;
  display: inline-block;
  content: "";
}

.rdrDateRangePickerWrapper {
  width: 100%;
}

.rdrCalendarWrapper {
  width: 100%;
}

.rdrMonthAndYearWrapper {
  padding-top: 0px !important;
  height: auto !important;
}

.rdrMonthName {
  padding: 0.2rem 0.85rem !important;
}

.rdrWeekDay {
  line-height: auto !important;
}

.rdrDay {
  height: 2.5rem !important;
}

.search-control {
  font-size: .85rem;
  font-style: normal;
  font-weight: 400;
  color: var(--dark-color);
  border-radius: 0.25rem;
  padding: 0.2rem 1rem !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.search-control::placeholder,
.search-control:-ms-input-placeholder,
.search-control::-ms-input-placeholder {
  color: rgba(113, 121, 134, 0.75) !important;
  opacity: 1;
}

.number {
  width: 100px;
}

.notes {
  height: 100px !important;
  width: 100%;
}

.label-total {
  padding-top: 3px;
  font-weight: bold;
}

.react-datepicker:has(.react-datepicker__time-container) {
  width: 330px;
}

/* Button */
.btn {
  text-align: center;
  display: inline-block;
  border-radius: 0.25rem;
  padding: 0.4rem 1.5rem;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  font-size: .85rem !important;
  line-height: 16px;
}

.btn-block {
  width: 100% !important;
}

.btn-primary {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}

.btn-search {
  border: 1px solid var(--green-color) !important;
  background-color: var(--green-color) !important;
  color: var(--light-color);
}

.btn-delete {
  border: 1px solid var(--danger-color) !important;
  background-color: var(--danger-color) !important;
  color: var(--light-color);
}

.btn-primary:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}

.btn-primary-outline {
  border: 1px solid var(--primary-color) !important;
  background-color: transparent !important;
  color: var(--primary-color);
}

.btn-primary-outline:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}

.btn-primary.dropdown-toggle::after {
  color: var(--light-color) !important;
}

.btn-signup {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1rem !important;
  line-height: 18px;
}

.btn-module {
  padding: 0.2rem 1.5rem;
}

.btn-module span {
  margin-top: 0.25rem;
}

.btn-export {
  padding: 0.4rem 0.6rem;
}

.dropdown-toggle::after {
  margin-left: 0.555em;
  vertical-align: 0.155em;
}

.limit-menu {
  transform: translate3d(0px, 26px, 0px) !important;
  min-width: 75px !important;
}

.btn-prevnex,
.btn-apply {
  border: 1px solid var(--light-color) !important;
  padding: 0.2rem 1.5rem;
  background-color: var(--light-color) !important;
  color: var(--primary-color);
}

.btn-prevnex:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
  background-color: var(--grey-color) !important;
  color: var(--primary-color);
}

.btn-apply {
  padding: 0.2rem 0.5rem;
}

.btn-save,
.btn-back {
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0.2rem 1rem;
}

.btn-save:hover,
.btn-back:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}

.btn-save {
  border: 1px solid var(--light-color) !important;
  background-color: var(--light-color) !important;
  color: var(--primary-color);
}

.btn-save2 {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--light-color) !important;
  color: var(--primary-color);
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 10px 20px;
}

.btn-save:hover {
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}

.btn-back {
  border: 1px solid var(--light-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}

.btn-back:hover {
  background-color: var(--light-color) !important;
  color: var(--primary-color);
}

/* Error */
.notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  line-height: 1.4;
  padding-left: 20px;
  padding-right: 20px;
}

.notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.notfound .notfound-404 h1 {
  color: var(--light-color);
  font-weight: 900;
  font-size: 276px;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound h2 {
  font-size: 46px;
  color: var(--dark-color);
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
}

.notfound p {
  font-size: 16px;
  color: var(--dark-color);
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}

.notfound a {
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  background: var(--primary-color);
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: var(--light-color);
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

/* Navbar */
.navbar {
  background-color: var(--light-color);
  box-shadow: 0 1px 1px 1px rgba(18, 106, 211, 0.08);
}

.sidemenu-opener {
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 5px;
  border-radius: 5px;
  padding-top: 8px;
  background-color: var(--light-color);
  box-shadow: 0 1px 1px 1px rgba(18, 106, 211, 0.18);
  cursor: pointer;
}

.nav-opener {
  cursor: pointer;
}

.nav-opener svg {
  color: #4a4a4a;
}

.sidemenu-opener.hidden {
  background-color: var(--light-color) !important;
}

.navbar-brand {
  padding: 0px;
}

.navbar-brand img {
  height: 30px !important;
  margin-left: 5px;
}

.notification {
  color: var(--dark-color);
  padding: 8px 0px;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgb(177 177 189 / 52%) !important;
}

/* Navbar Profile */
.user-profile {
  padding: 0px;
}

.user-name {
  font-weight: 500 !important;
  line-height: 1rem;
  color: var(--dark-color);
}

.user-status {
  font-weight: 400 !important;
  font-size: .85rem;
  line-height: .85rem;
  color: rgba(91, 91, 91, 0.7);
}

.avatar {
  white-space: nowrap;
  background-color: #c3c3c3;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgb(177 177 189 / 52%) !important;
  position: relative;
  display: inline-flex;
}

.avatar img {
  border-radius: 50%;
  height: 35px;
  width: 35px;
}

.dropdown-toggle::after {
  color: var(--dark-color);
}

.dropdown-menu {
  width: 200px;
  border: 0;
  right: 10px;
  left: auto;
  top: 100%;
  padding: 0;
  margin-top: -5px;
  margin-right: -17px;
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  z-index: 100;
  margin-top: 0.1px;
}



.dropdown-item {
  font-weight: 500;
  color: rgba(113, 121, 134);
  padding: 7px 10px;
}

.dropdown-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  color: var(--dark-color);
}

.dropdown-text {
  font-weight: 400 !important;
  font-size: .85rem;
}

.logout {
  color: rgba(255, 40, 92, 1) !important;
  font-weight: 500 !important;
}

/* Sidebar */
.sidebar {
  padding-top: 10px !important;
  height: 100%;
  display: none;
  overflow: auto;
  padding: 0px 0px 15px 0px;
  background-color: var(--light-color) !important;
  box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.1);
}

.sidebar.active {
  display: block;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;
  background-color: var(--primary-color);
}

.sidebar-sticky .nav {
  min-height: calc(100vh - 115px);
}

.nav-arrow {
  width: 8px;
  height: 8px;
}

.nav-arrow.down {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.nav-link {
  font-weight: 500;
  color: rgba(113, 121, 134);
  padding: 3px 10px;
  cursor: pointer;
}

.nav-link.active {
  background-color: rgba(52, 174, 226, 0.15);
}

.nav-link.active span,
.nav-link.active svg {
  color: var(--primary-color);
}

.sidebar-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.sidebar-text {
  font-weight: 300 !important;
  font-size: .85rem;
}

.nav-submenu .nav-item .nav-link {
  padding-left: 30px;
}

/* Login */
.login-card {
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  width: 400px;
}

.login-header {
  margin-bottom: 5vh;
  text-align: center;
}

.login-header img {
  width: 200px;
}

.login-title {
  font-weight: 600;
  font-size: 24px;
}

.login-label {
  color: var(--dark-color);
  font-size: .85rem;
}

.remember-me {
  line-height: 22px;
  font-size: .85rem;
}

.forget-password {
  color: var(--primary-color);
  line-height: 22px;
  font-size: .85rem;
}

/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin-left: 5px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: var(--primary-color);
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Bottom Nav */
.bottom-nav {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: var(--light-color);
  border-top: 1px solid var(--grey-color) !important;
  justify-content: space-evenly;
  direction: ltr;
  z-index: 999;
  opacity: 2;
}

.footer-nav {
  display: flex;
  padding-left: 0 !important;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: var(--light-color);
  border-top: 1px solid var(--grey-color) !important;
  justify-content: space-between;
  z-index: 3;
  direction: ltr;
}

.footer-nav-link {
  text-decoration: none;
  width: 100px;
}

.mobile-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 600px) {
  .custom-sticky {
    width: 100%;
    height: 60px;
    background-color: var(--light-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .FooterModule {
    background-color: var(--light-color);
    display: block;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    text-align: center;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    box-shadow: 0px 1px 5px 1px rgba(69, 65, 78, 0.08);
    max-height: 0px !important;
    overflow: hidden;
    transform: translateY(100%) !important;
    transition: all 0.5s ease-in-out;
    opacity: 0;
  }

  .FooterModule.show {
    padding-top: 0 !important;
    max-height: 500px !important;
    padding: 15px !important;
    transform: translateY(0) !important;
    overflow: auto !important;
    z-index: 998 !important;
    transition: all 0.5s ease-in-out;
    opacity: 1;
  }


  .FooterModule {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    transform: translateY(100%);
  }

  .FooterModule.show {
    padding-top: 0 !important;
    max-height: 300px;
    /* adjust as needed */
    opacity: 1;
    transition: all 0.5s ease-in-out;
    transform: translateY(0);
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 93.5%;
    background: rgba(0, 0, 0, 0.2);
    /* Adjust the opacity as needed */
    z-index: 997;
    /* Make sure it's behind the menu */
  }

  .hiasanFooter {
    margin: 0 auto;
    width: 80px;
    background-color: var(--grey-color);
    height: 8px;
    border-radius: 10px;
    z-index: 1000;
    /* increase z-index to ensure it's on top */
  }


}



.footer-nav-icon {
  height: 24px;
}

#footerNav:hover {
  color: #2cacdb;
}

#footerIcon {

  gap: 5px;
  justify-content: center;
  align-items: center;
}



#master-icon {
  background-color: #2cacdb;
  /* Ganti dengan warna yang Anda inginkan */
  border-radius: 50%;
  /* Membuat bentuk lingkaran */
  padding: 10px;
  /* Sesuaikan padding agar lingkaran sesuai dengan ikon */
  /* Agar konten tetap berada di tengah secara horizontal */
  width: 50px !important;
  /* Sesuaikan ukuran lingkaran */
  height: 50px !important;
  /* Sesuaikan ukuran lingkaran */
  color: white;
}

.footer-nav-icon svg {

  width: 24px;
  height: 24px;
}

.footericon svg {
  width: 24px;
  height: 24px;
}


.nav-component.show {
  color: #2cacdb;
  background-color: white;
}

.nav-component:hover {
  color: #2cacdb;
  background-color: white;
}




.nav-dashboard-component:hover {
  color: #2cacdb;
  background-color: white;
}

.bottom-nav-link:active {
  background-color: white;
  color: #2cacdb;
}


.wrap {
  padding-top: 8px;
  text-align: center;
  height: 24px;
  color: var(--grey-color);
}

.wrap.active {
  color: var(--primary-color);
}



.bottom-nav-icon {
  height: 24px;
}

.bottom-nav-icon svg {
  width: 24px;
  height: 24px;
}


/* Bottom Nav*/
.bottom-nav {
  display: none;
}

/* Module */
.module {
  background-color: var(--primary-color);
  color: var(--light-color);
  padding: 0.25rem 0.75rem;
}

.module-img {
  height: 1.5rem;
  margin-right: 10px;
}

.module-title {
  font-weight: 600;
  font-size: 20px;
  margin-top: 2px;
}

.content {
  padding: 20px;
}

.list-card-header {
  padding: 5px 22px !important;
  flex: 0 0 55px !important;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
}

.list-card-body {
  padding: 10px;
  background: #fff;
  border-radius: 0 0 5px 5px;
}

.filter {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.main {
  height: 100%;
  padding: 0px;
  min-height: calc(100vh - 130px);
  overflow: scroll;
}

.detail {
  border: 1px solid #eaeaea;
  margin: 0 0px;
  padding: 20px 20px;
  height: 100%;
  box-shadow: 0px 1px 5px 1px rgba(69, 65, 78, 0.08);
}

.reportHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.reportHeader .label {
  padding-top: 4px !important;
}

.stickes {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

/* Table */
@media (min-width: 600px) {
  .modal-dialog {
    max-width: 800px;
    max-height: 700px;
    margin: 1.75rem auto;
  }

  .periodinfo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

.table-list td,
.table-list th {
  padding: 0.35rem;
  font-size: .85rem;
  font-weight: 300;
  vertical-align: middle;
  color: var(--dark-color);
}

.table-list thead td,
.table-list thead th,
.table-list tfoot td,
.table-list tfoot th {
  background-color: var(--primary-color);
  font-size: .85rem;
  font-weight: 600;
  color: #ffffff;
}

.table-list .header {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.table-list .text-right {
  padding-right: 20px;
}

.table-list .sorting {
  background-image: url("assets/images/sort_both.png");
}

.table-list .sorting_asc {
  background-image: url("assets/images/sort_asc.png");
}

.table-list .sorting_desc {
  background-image: url("assets/images/sort_desc.png");
}

.table-list .data-item {
  cursor: pointer;
}

.download-link {
  color: var(--dark-color);
  cursor: pointer;
}

.CANCELED {
  text-decoration: line-through;
}

/* Mobile */
.narrow-screen {
  display: none !important;
}

.module-card {
  margin-bottom: 2rem;
  border-radius: .428rem;
}

.module-card-body {
  padding: 0px;
}

.module-card-left {
  margin-left: -5px;
  padding-right: 8px;
  margin-right: 5px;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #C0C0C0;
}

.module-card-right {
  position: absolute;
  right: 0%;
  margin-right: 5px
}

.module-card-btn {
  padding: .486rem 1rem;
}

.dropdown-toggle.hide-arrow:after {
  display: none;
}

.module-card-title {
  font-weight: bold;
  font-size: 17px;
  padding: 1px;
  cursor: pointer;
}

.module-card-data {
  display: flex;
  gap: 50px;
}

.module-card-data div {
  display: flex;
  gap: 20px;
}

.module-card-data h6 {
  width: 100px;
  margin-top: 5px !important;
}

.module-card-data div h6 {
  width: 120px;
  margin-top: 5px !important;
}

.module-card-data p {
  font-weight: 800;
}

.module-card .dropdown-item {
  cursor: pointer;
}

.mobile-card-container {
  width: 100% !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  padding-top: 10px !important;
  overflow: hidden;
}



.mobile-card-single {
  width: 25%;
  display: flex;
  padding: 5px 15px;
  flex-direction: column;
  text-decoration: none;
  color: var(--primary-color);
  align-items: center;
}



.mobile-card-scaler {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.mobile-card-single:hover {
  .mobile-card-scaler {
    background-color: #2cacdb;
    color: white;
    border: 1px solid var(--light-color);
    border-radius: 20px;
  }

  .mobile-card-text {
    color: #2CACDB;
    font-weight: 700;
  }

}

.mobile-card-image {
  display: flex;
  justify-content: center;
  align-items: center !important;
  border-radius: 20px;
  height: 60px;
  width: 60px;
  border: 1px solid var(--primary-color);
}



.mobile-card-image svg {
  width: 50%;
  height: 50%;
  margin-left: 10px;
}



.mobile-card-text {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}

.map {
  height: calc(100% - 35px);
  min-height: 500px;
  border: 1px solid var(--grey-color);
}

.map-container {
  height: 100vh;
  width: 100%;
}

.map-container .col-sm-8 {
  height: 100%;
}

.mapComponentNew {
  border: 1px solid var(--grey-color);

  >div:first-child {
    flex-wrap: wrap;

    >div:first-child {
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      min-height: 50vh;
    }
  }

  .detail {
    max-height: 50vh;
    overflow-y: auto;
  }
}

.directions-title {
  font-size: 1.2rem;
  font-weight: 800;
}

.directions-route {
  font-size: 0.9rem;
  font-weight: 300;
}

.order-status {
  padding: 20px;
}

.order-status-item {
  margin-right: 35px;
  padding-right: 15px;
  border-top: 1px solid var(--grey-color);
  border-right: 1px solid var(--grey-color);
  border-bottom: 1px solid var(--grey-color);
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.order-status-mark {
  margin-left: -1.5rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 1px solid var(--grey-color);
  padding: 3px;
}

.order-status-icon {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.order-status-icon svg {
  color: var(--dark-color);
}

.order-status-icon.active {
  background-color: var(--primary-color);
}

.order-status-icon.active svg {
  color: var(--light-color);
}

.order-status-text {
  padding-right: 8px !important;
  padding-left: 8px !important;
  font-weight: 600;
}

.item-table .table-responsive {
  padding-bottom: 75px;
}

/* Dashboard */
.dashboard {
  color: var(--primary-color);
  padding: 0.75rem 1rem;
  font-size: 1.2rem;
}

.dashboard-title {
  font-weight: 600;
  font-size: 1.5rem;
}

.dashboard-click {
  cursor: pointer;
}

.btn-sync {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--light-color) !important;
  color: var(--primary-color);
  padding: 0.375rem 0.75rem;
  line-height: .8rem;
}

.btn-sync:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}

/* Achivement */
.achievement-wrapper {
  background: linear-gradient(43deg, var(--dark-color) 0%, var(--dark-primary-color) 35%, var(--primary-color) 100%);
  margin-bottom: 1.2rem;
  padding: 15px !important;
  color: var(--light-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 5px;
}

.achievement-head {
  position: relative;
  margin-bottom: 60px;
}

.achievement-head:before {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  content: "";
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
}

.achievement-head:after {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 1;
}

.achievement-head-title {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  float: left;
}

.achievement-head-title small {
  display: block;
  font-size: .85rem;
  font-weight: 400;
  line-height: normal;
  color: var(--grey-color);
}

.achievement-head-option {
  font-size: 16px;
  font-weight: 400;
  float: right;
}

.achievement-content-left {
  float: left;
  padding: 10px 30px;
  color: #333;
  background-color: #e7f2ee;
  border-radius: 5px;
}

.achievement-content-left .mc-fa {
  color: #439678;
  border: 2px solid #439678;
  border-radius: 100%;
  padding: 5px;
  font-size: 22px;
  height: 38px;
  width: 38px;
  vertical-align: middle;
}

.achievement-content-left div {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
}

.achievement-content-right {
  float: right;
  margin-top: 10px;
  position: relative;
}

.achievement-content-right div {
  float: left;
  text-align: center;
  margin-right: 60px;
  position: relative;
}

.achievement-content-right .mc-fa {
  position: absolute;
  top: 0;
  right: -30px;
  font-size: 18px;
}

.achievement-content-left div small,
.achievement-content-right div small {
  display: block;
  font-size: 1rem;
  font-weight: 500;
}

.achievement-content-left strong,
.achievement-content-right strong {
  display: block;
  font-size: 28px;
  line-height: normal;
}

.achievement-content-right:before {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  content: "";
  position: absolute;
  bottom: 0px;
  right: 10px;
  z-index: 1;
}

.achievement-content-right:after {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  content: "";
  position: absolute;
  bottom: -20px;
  right: -10px;
  z-index: 1;
}

/* Fleet Order */
.fleet-order-wrapper {
  padding: 15px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 1.2rem;
}

.fleet-order-head {
  position: relative;
  margin-bottom: 30px;
}

.fleet-order-head-title {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  float: left;
}

.fleet-order-head-title small {
  display: block;
  font-size: .85rem;
  font-weight: 400;
  line-height: normal;
  color: var(--grey-color);
}

.fleet-order-head-option {
  font-size: 16px;
  font-weight: 400;
  float: right;
}

.fleet-order-head-option-item label {
  margin-bottom: 0;
  color: var(--primary-color);
}

.fleet-order-content-item div {
  position: relative;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}

.fleet-order-content div small {
  display: block;
  font-size: 1rem;
  font-weight: 500;
}

.fleet-order-content div strong {
  display: block;
  font-size: 28px;
  line-height: normal;
}

.fleet-order-content-item div .mc-fa {
  position: absolute;
  bottom: 10px;
  right: 1px;
  padding: 10px;
  height: 42px;
  width: 42px;
  border-radius: 100%;
  text-align: center;
}

.fleet-order-content-item .mc-orange {
  color: #ffa740;
  background-color: #fff3e6;
}

.fleet-order-content-item .mc-purple {
  color: #282561;
  background-color: #ebecef;
}

.fleet-order-content-item .mc-green {
  color: #429677;
  background-color: #e7f2ee;
}

.chart {
  height: 195px;
}

.chart canvas {
  width: 100% !important;
  height: 100% !important;
}

.table-reminder th,
.table-reminder td {
  font-size: .85rem;
  padding: 5px 10px;
}

.table-reminder .title {
  font-weight: bold;
}

.table-reminder .badge {
  padding: 5px 10px;
}

/* Map */
.map-wrapper {
  padding: 15px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 1.2rem;
}

/* Order */
.order-wrapper {
  padding: 15px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 1.2rem;
}

.order-head {
  position: relative;
  margin-bottom: 30px;
}

.order-head-title {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  float: left;
}

.order-head-title small {
  display: block;
  font-size: .85rem;
  font-weight: 400;
  line-height: normal;
  color: var(--grey-color);
}

.order-head-option {
  font-size: 16px;
  font-weight: 400;
  float: right;
}

.order-head-option-item label {
  margin-bottom: 0;
  color: var(--primary-color);
}

.order-content-item div {
  position: relative;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  color: var(--dark-color);
}

.order-content div small {
  display: block;
  font-size: .85rem;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  padding-left: 15px;
}

.order-content-item small:before {
  background-color: #67718b;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 100%;
  top: 6px;
  position: absolute;
  left: 0;
}

.order-content-item small.mc-orange {
  color: var(--orange-color);
}

.order-content-item small.mc-orange:before {
  background-color: var(--orange-color);
}

.order-content-item small.mc-blue {
  color: var(--blue-color);
}

.order-content-item small.mc-blue:before {
  background-color: var(--blue-color);
}

.order-content-item small.mc-green {
  color: var(--green-color);
}

.order-content-item small.mc-green:before {
  background-color: var(--green-color);
}

.order-content-item small.mc-red {
  color: var(--red-color);
}

.order-content-item small.mc-red:before {
  background-color: var(--red-color);
}

.order-content div strong {
  display: block;
  font-size: 20px;
  line-height: normal;
  text-align: right;
}

.order-content-total div {
  position: relative;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  color: var(--dark-color);
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 5px;
  text-align: center;
  margin-top: -10px;
}

.order-content-total div small {
  padding-left: 0px;
}

.order-content-total div strong {
  display: block;
  font-size: 28px;
  text-align: center;
}

.equal {
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}

.social-icon {
  transition: transform 0.3s;
  margin-left: 6px;
}

.icon-hover {
  transform: scale(1.2);
}

.social-icon.instagram {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.image-container {
  text-align: center;
  padding-top: 20px;
}

.button-container {
  text-align: center;
  margin-top: 20px;
}

.join-button {
  background-color: white;
  color: #59AFEC;
  margin-left: 10px;
  border: 1px solid #59AFEC;
}

/* .join-button:hover{
  background-color: none;
  color: white
} */
.swal-footer {
  text-align: center;
}

.text-community {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.long-input {
  width: 100%;
}

.circular-image-container {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid #007bff; */
}

.circular-image-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.circular-image-container img {
  width: 70%;
}


.circular-image-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.save-button {
  background-color: white;
  color: #59AFEC;
  border: 1px solid #59AFEC;
  margin-bottom: 16px;
  font-size: 14px;
  height: 30px !important;
  width: 300px !important;
}

.upload-button:hover {
  background-color: #007bff;
  color: white;
}

.upload-button {
  background-color: white;
  color: #59AFEC;
  border: 1px solid #59AFEC;
  border-radius: 0.25rem;
  position: relative;
  width: 105px;
  height: 30px;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.upload-button p {
  position: relative;
  text-align: center;
  margin-top: 5px;
}

.upload-button input {
  width: 320px;
  height: 40px;
  padding: 0 10px;
  background: transparent;
  border: 1.2px solid black;
  outline: none;
  border-radius: 5px;
  position: absolute;
  outline: none;
  opacity: 0;
}

.invite-button {
  background-color: #59AFEC;
  color: white;
  margin-left: 10px;
  border: 1px solid #59AFEC;
  flex-direction: column;
  /* margin-bottom: 250px !important; */
  /* margin-left: -105px !important;
  text-align: center !important; */
}

.invite-button:hover {
  background-color: white;
  color: #2cacdb;
  border: 1px solid #59AFEC;
}

.save-button-license {
  background-color: #2b9af3;
  color: white;
  margin-left: 10px;
  border: 1px solid #2b9af3;
  width: 130px;
  height: 30px;
  border-radius: 10px;
}

.save-button-license:disabled {
  background-color: #2b9af3;
  color: white;
  margin-left: 10px;
  border: 1px solid #2b9af3;
  width: 130px;
  height: 30px;
  border-radius: 10px;
  cursor: not-allowed;
}

.save-button-license:hover {
  background-color: white;
  color: #2cacdb;
  border: 1px solid #59AFEC;
}

.leave-button-container {
  display: flex;
  justify-content: flex-end;
}

.driver-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 23px 100px 23px;
}

.driver-page-cash {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
  padding: 0 12px;
}

.ongoing-order {
  font-family: "Roboto";
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: rgba(32, 201, 151, 0.3);
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  padding: 10px 15px 10px 23px;
}

.green-text {
  color: #4ea699;
  /* margin-top: 11px; */
}

.blue-text {
  color: var(--primary-color) !important;
  margin-top: 11px;
}

.driver-card-list {
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  margin-top: 10px;
  padding: 10px 15px 10px 23px;
  position: relative;
}

.driver-card-list {
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  margin-top: 10px;
  padding: 10px 15px 10px 23px;
  position: relative;
}

.driver-card-list1 {
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  margin-top: 10px;
  padding: 10px 15px 10px 23px;
  position: relative;
}

.driver-card-list2 {
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  margin-top: 10px;
  padding: 10px 15px 10px 23px;
  position: relative;
}

.driver-card-list3 {
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  margin-top: 10px;
  padding: 10px 15px 10px 23px;
  position: relative;
}

.driver-card-list4 {
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  margin-top: 10px;
  padding: 10px 15px 10px 23px;
  position: relative;
}

.driver-card-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  /* Lebar samping kiri */
  background-color: #12a3dc;
  /* Ganti dengan warna yang diinginkan */
  border-radius: 20px 0 0 20px;
  /* Border-radius sesuai dengan elemen utama */
}

.driver-card-list1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  /* Lebar samping kiri */
  background-color: #00c614;
  /* Ganti dengan warna yang diinginkan */
  border-radius: 20px 0 0 20px;
  /* Border-radius sesuai dengan elemen utama */
}

.driver-card-list2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  /* Lebar samping kiri */
  background-color: #DC8612;
  /* Ganti dengan warna yang diinginkan */
  border-radius: 20px 0 0 20px;
  /* Border-radius sesuai dengan elemen utama */
}

.driver-card-list3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  /* Lebar samping kiri */
  background-color: #d11313;
  /* Ganti dengan warna yang diinginkan */
  border-radius: 20px 0 0 20px;
  /* Border-radius sesuai dengan elemen utama */
}

.driver-card-list4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  background-color: #6c757d;
  border-radius: 20px 0 0 20px;
}

.origin-destination {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  padding: 0 2px;
  width: 100%;
  color: #4ea699;
}

.fleet-driver {
  display: flex;
  flex-direction: column;
  background-color: rgb(18 163 220 / 10%);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  gap: 5px;
  width: 105px;
  margin-bottom: 20px;
  color: #12a3dc;
}

.fleet-driver span {
  border: solid 0.5px;
  width: 80%;
}

.take-order {
  border-radius: 16px;
  align-self: center;
  justify-content: center;
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0.2rem 0.5rem;
}

.active-order-card {
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  padding: 27px 15px;
}

.card-header-customer {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 5px;
}

.card-customer {
  color: #000;
  font-size: 14px;
  font-weight: normal;
}

.origin-text2 {
  color: #7d83ff;
}

.item-detail1 {
  color: #4a4a4a;
  text-decoration-line: underline;
}

.destination-text {
  color: #7d83ff;
  margin: auto 0;
}

.view-map {
  color: #fff;
  border-radius: 19.5px;
  background-color: #2cacdb;
  margin-top: 24px;
  justify-content: center;
  text-align: center;
  padding: 10px 60px;
  font: 600 13px
}

.driver-greeting {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  margin-left: 4px;
}

.driver-txt {
  margin-left: 4px;
}

.driver-request {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin-top: 48px;
}

.driver-cash {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  font-family: "Roboto";
}

.txt-card {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto";
  padding: 0 16px;
}

.container-card-cash {
  width: 92%;
  height: 135px;
  border: 1px solid #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  display: inline-block;
}

.icon-cash {
  position: relative;
}

.icon-cash span {
  position: relative;
  /* top: -44px; */
  /* margin-left: 10px; */
}

.button-cash {
  height: 34px;
  width: 314px;
  border-radius: 10px;
}

.driver-order-status1 {
  color: #fff;
  border-radius: 16px;
  background-color: #00c614;
  justify-content: center;
  padding: 5px 10px;
}

.driver-order-status {
  color: #fff;
  border-radius: 16px;
  background-color: #12a3dc;
  justify-content: center;
  padding: 5px 10px;
}

.driver-order-status2 {
  color: #fff;
  border-radius: 16px;
  background-color: #DC8612;
  justify-content: center;
  padding: 5px 10px;
}

.driver-order-status3 {
  color: #fff;
  border-radius: 16px;
  background-color: #d11313;
  justify-content: center;
  padding: 5px 10px;
}

.driver-order-status4 {
  color: #fff;
  border-radius: 16px;
  background-color: #6c757d;
  justify-content: center;
  padding: 5px 10px;
}

.swal2-styled {
  padding: 10px 45px;
  font-size: 20px !important;
}

.swal2-confirm {
  background-color: #2cacdb !important;
}

.driver-newRequest {
  border-radius: 13px;
  border: 1px solid #2cacdb;
  background-color: #fff;
  display: flex;
  gap: 3px;
  padding: 2px 8px;
  font: 500 10px;
  color: #2cacdb;
  align-self: center;
}

.driver-order-modal,
.driver-shipment-route {
  border-radius: 13px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.driver-shipment-route {
  margin: 10px 0;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 20%);
  align-items: center;
  z-index: 1;
}

.progress-bar {
  list-style: none;
  font-size: 13px;
  font-weight: 700;
  counter-reset: container 0;
  background-color: #fff;
  position: relative;
  left: -50px;
  width: 120% !important;
}

.map-modal-header .btn-close {
  --bs-btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414'/%3E%3C/svg%3E");
  margin-right: 0;
  margin-left: auto;
  padding: 1rem;
  background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
}

.map-modal-header .btn-close:hover {
  color: #ff5555; /* Change the hover color */
}
.progress-bar li {
  position: relative;
  margin-left: 40px;
  margin-top: 50px;
  counter-increment: container 1;
  color: #6e6b7b;
}

.progress-bar li::before {
  content: counter(container);
  line-height: 30px;
  text-align: center;
  position: absolute;
  height: 30px;
  width: 30px;
  border: 1px solid #6e6b7b;
  border-radius: 50%;
  left: -40px;
  top: -5px;
  z-index: 10;
  background-color: #6e6b7b;
}


.progress-bar li::after {
  content: '';
  position: absolute;
  min-height: 90px !important;
  width: 2px;
  background-color: #6e6b7b;
  z-index: 1;
  left: -26px;
  top: -70px;
}

.progress-bar .step0::before {
  padding-top: 3px !important;
  padding-left: 1px;
  content: url('./assets/images/mark-origin.png');
}

.progress-bar .step2::before {
  padding-top: 2px !important;
  padding-left: 1px;
  content: url('./assets/images/mark-location.png');
}

.progress-bar .step1.active::before {
  padding-left: 1px;
  content: url('./assets/images/mark-checklist.png');
}



.progress-bar li.active::after {
  background-color: #12a3dc;

}

.progress-bar li:first-child:after {
  display: none;
}

.progress-bar li.active::before {
  color: #6e6b7b;
  background-color: #12a3dc;
  border: 1px solid #6e6b7b;
}

.progress-bar li.active {
  color: #12a3dc;
}

.to-dest {
  margin-top: 100px;
  display: flex;
  display: block;
  margin-bottom: 15px;
  margin-left: auto;
}

.from-to-container {
  margin-right: 10px;
}

.notes {
  height: 90px !important;
}

.input-group {
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  height: calc(1em + 1.3rem) !important;
  position: relative;
  gap: 10px;
}

.input-group label {
  position: absolute;
  left: 6px;
  transform: translateY(-50%);
  font-size: 14px;
  color: black;
  padding: 0 5px;
  pointer-events: none;
  transition: .5s;
  background: white;
  top: 0;
}

.input-group input {
  width: 900px;
  max-width: 900px;
  font-size: 16px;
  padding: 0 10px;
  background: transparent;
  border: 1.2px solid black;
  outline: none;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.input-group input:focus~label,
.input-group input:valid~label {
  top: 0;
  font-size: 12px;
  background: white;
}

/* form-input-group */
.form-input-group {
  font-style: normal;
  font-weight: 800;
  font-size: 0.8rem !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  height: calc(1rem + 1.3rem + 25px) !important;
  position: relative;
  gap: 10px;
  border: 1px dashed grey;
}

.form-input-group label {
  position: absolute;
  left: 6px;
  transform: translateY(-50%);
  font-size: 14px;
  color: black;
  padding: 0 5px;
  pointer-events: none;
  transition: .5s;
  background: white;
  top: 0;
}

.form-input-group input {
  width: 320px;
  height: 40px;
  font-size: 16px;
  padding: 0 10px;
  background: transparent;
  border: 1.2px solid black;
  outline: none;
  border-radius: 5px;
  position: absolute;
  outline: none;
  opacity: 0;
}

.form-input-group p {
  position: relative;
  font-weight: 100;
  top: auto;
  margin-top: 15px;
  text-align: center;
  color: grey;
}

.form-input-group input:focus~label,
.form-input-group input:valid~label {
  top: 0;
  font-size: 12px;
  background: white;
}

/* organization-input-group */
.organization-input-group {
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  height: 50px !important;
  width: 300px !important;
  position: relative;
  border: 1px dashed grey;
}

.organization-input-group label {
  position: absolute;
  left: 6px;
  transform: translateY(-50%);
  font-size: 12px;
  color: black;
  padding: 0 5px;
  pointer-events: none;
  transition: .5s;
  background: white;
  top: 0;
}

.organization-input-group input {
  /* width: 320px;
  height: 40px; */
  font-size: 12px;
  padding: 0 10px;
  background: transparent;
  border: 1.2px solid black;
  outline: none;
  border-radius: 5px;
  position: absolute;
  outline: none;
  opacity: 0;
}

.organization-input-group p {
  position: relative;
  font-weight: 100;
  top: auto;
  margin-top: 15px;
  text-align: center;
  color: grey;
}

.organization-input-group input:focus~label,
.organization-input-group input:valid~label {
  top: 0;
  font-size: 12px;
  background: white;
}

.organization-input-group:hover {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
}

.container-invoice {
  width: 100%;
  height: 100px;
  border: 1px solid #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.icon-invoice {
  margin-left: 20px;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 700;
  color: #000;
  position: relative;
  margin-right: 10px;
}

.icon-invoice div {
  margin-top: 5px;
  font-size: 16px;
  align-items: center;
  font-weight: 400;
}

.circle-invoice {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-box div {
  width: 100px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.status-box {
  flex-direction: column;
  margin-top: 6px;
}

.status-box .pending {
  background-color: #DC8612;
}

.status-box .paid-off {
  background-color: #41B06E;
}

.status-box .cancel {
  background-color: #ea5555;
}

.status-box .expired {
  background-color: #7C7E82;
}

.status-box .waiting {
  background-color: #FFC107;
}

.status-box span {
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  color: black;
  text-align: center;
}

.status-box-list div {
  width: 100px;
  height: 34px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.status-box-list {
  flex-direction: column;
  margin-top: 6px;
}

.status-box-list .pending {
  background-color: #FF0000;
}

.status-box-list .paid-off {
  background-color: #1BBF00;
}

.status-box-list span {
  font-size: 18px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  color: black;
  text-align: center;
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.status-box2,
.status-box {
  display: flex;
  align-items: center;
}

.status-box2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-top: 30px;
  gap: 20px;
}

.status-box2 button {
  border: none;
  color: white;
  width: 100px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.status-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.pending,
.paid-off {
  background-color: white;
  color: #12A3DC;
  padding: 5px 10px;
  border-radius: 5px;
}

.icon-bookmark {
  width: 40px;
  height: 40px;
}

.icon-paid {
  width: 150px;
  height: 150px;
}

.invoice-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.invoice-date {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
}

.total-invoice {
  text-align: center;
  margin-top: 30px;
}

.total-invoice p {
  font-size: 18px;
  font-weight: 600;
}

.total-invoice h2 {
  font-weight: 700;
  color: black;
}

.invoice-detail p {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.invoice-detail h2 {
  text-align: center;
  color: black;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
}

.img-invoice {
  text-align: center;
  margin-top: 30px;
}

#invoiceContent {
  width: 100%;
  max-width: 700px;
  margin: auto;
  overflow: hidden;
}

.driver-modal-box {
  border-left: 15px solid #2cacdb;
  border-right: 15px solid #2cacdb;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.driver-modal-box img {
  margin-left: 50px;
}

.modal-signed {
  border-radius: 13px;
  height: 35px;
  align-self: end;
  margin-top: 30px;
  justify-content: center;
  padding: 3px 20px;
  border: 1px solid var(--dark-color) !important;
  background-color: var(--dark-color) !important;
  color: var(--light-color);
}

.modal-start {
  border-radius: 13px;
  height: 35px;
  align-self: end;
  margin-top: 20px;
  justify-content: center;
  padding: 3px 20px;
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}

.modal-cancel {
  border-radius: 13px;
  height: 35px;
  align-self: end;
  margin-top: 20px;
  justify-content: center;
  padding: 3px 20px;
  border: 1px solid var(--orange-color) !important;
  background-color: var(--orange-color) !important;
  color: var(--light-color);
}

.modal-btn-delete {
  border-radius: 13px;
  align-self: end;
  margin-top: 70px;
  justify-content: center;
  padding: 3px 20px;
  border: 1px solid var(--danger-color) !important;
  background-color: var(--danger-color) !important;
  color: var(--light-color);
}

.add-photo {
  border-radius: 13px;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: var(--light-color);
  width: 120px;
}

.add-photo2 {
  border-radius: 4px;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: var(--light-color);
  justify-content: center;
  display: flex;
  padding: 15px;
  width: 50px;
  height: 50px;
}

.photomodal {
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.photomodal2 {
  display: flex;
  justify-content: space-between;
}

.img1 {
  object-fit: cover;
  width: 55px;
  height: 55px;
  margin-top: 10px;
  border: 2px solid #2cacdb;
  border-radius: 5px;
}

.image-modal {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.react-html5-camera-photo>img,
.react-html5-camera-photo>video {
  width: 100% !important;
}


/* Mobile Cash Transfer */
.card-body h5 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #000000;
}

.card-body h4 {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.cashTransfer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.cashTransfer3,
.cashTransfer1,
.cashTransfer2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* margin-top: 10px; */
}

.cashTransfer2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 35px;
}

.date-input {
  display: flex;
  flex-direction: column;
  width: 95px;
  align-items: center;
}

.date-input label {
  font-weight: bold;
  padding-left: 3px;
}

.date-input input::placeholder {
  padding-right: 3px;
}

.group-request-date {
  display: flex;
  flex-direction: column;
}

.group-request-date label {
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  left: 5px;
  transform: translateY(-50%);
  pointer-events: none;
  background: white;
  transition: .5s;
}

.date-form {
  align-items: center;
  display: flex;
  gap: 10px;
}

.group-notes {
  display: flex;
  flex-direction: column;
  height: 88px;
  padding-left: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  position: relative;
}

.group-description {
  display: flex;
  flex-direction: column;
  position: relative;
}

.group-description label {
  background-color: white;
  font-size: 16px;
}


.group-tf,
.group-req,
.group-amount,
.group-remaining-cost,
.date-input {
  /* padding-left: 5px; */
  border: 0.5px solid rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 58px;
  position: relative;
}

.group-tf-req {
  width: 314px;
  display: flex;
  gap: 10px;
}

.group-tf,
.group-req {
  width: 154px
}

.group-amount,
.group-remaining-cost {
  width: 314px;
}

.group-amount label span {
  color: red;
}

.group-remaining-cost label,
.group-tf-req label,
.group-amount label,
.group-description label,
.group-notes label {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  left: 5px;
  transform: translateY(-50%);
  pointer-events: none;
  background: white;
  transition: .5s;
}

.group-request-date input:focus~label,
.group-request-date input:valid~label,
.group-description input:focus~label,
.group-description input:valid~label,
.group-amount input:focus~label,
.group-amount input:valid~label,
.group-req input:focus~label,
.group-req input:valid~label,
.group-tf input:focus~label,
.group-tf input:valid~label,
.group-remaining-cost input:focus~label,
.group-remaining-cost input:valid~label {
  top: 0;
  background: white;
}

.form-tf-req,
.form-amount,
.form-remaining-cost,
.form-date {
  outline: none;
  border-style: none;
}

.form-tf-req,
.form-amount,
.form-remaining-cost {
  padding-left: 16px;
}

.form-remaining-cost,
.form-amount,
.form-amount,
.form-date,
.form-date {
  background: transparent;
  padding-top: 16px;
}

.form-notes {
  outline: none;
  border-style: none;
  background: transparent;
  padding-top: 15px;
  padding-left: 10px;
}

.form-description {
  outline: none;
  border-style: none;
  background: transparent;
  padding-top: 15px;
  padding-left: 10px;
}

.form-date {
  text-align: center;
}

.form-tf-req {
  width: 100px;
  height: 40px;
  background: transparent;
  padding-top: 15px;
}

.input-amount span,
.input-tf-req span,
.input-remaining-cost span {
  padding-left: 10px;
}

/* Mobile cash Transfer */

/* Mobile Dashboard */

.main-content-card {
  font-family: "Roboto";
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 8px;
  min-width: 334px;
  margin-bottom: 5px;
}

.main-content-card div {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 188px;
  border-radius: 10px;
  color: #ffffff;
  margin-bottom: 5px;
}

.main-content-card2 {
  font-family: "Roboto";
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 8px;
  min-width: 334px;
  margin-bottom: 5px;
}

.main-content-card2 div {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 188px;
  height: 92px;
  border-radius: 10px;
  background-color: #2cacdb;
  color: #ffffff;
  margin-bottom: 15px;
}

.kasbon-dan-nomor div {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.kasbon-dan-nomor p {
  font-size: 14px;
  font-weight: 400;
}

.order-id {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18;
  font-weight: bold;
  color: #000000;
}

.order-id h5 {
  font-size: 16px;
  font-weight: 800;
  color: #000000;
}

.destination-order {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-bottom: 10px;
}

.destination-order {
  font-size: 12px;
  padding: 5px 0px;
  margin: 0px;
}

.order-Container {
  font-family: "Roboto";
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-Container span,
.shipment-list span,
.driver-card-list2 span {
  background-color: rgba(0, 0, 0, 0.3);
  height: 0.5px;
}

.order-schedule {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.date-hour-order {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px 0px;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  color: #000;
}

.date-hour-order div {
  width: 50px;
  text-align: left;
}

.shipmentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shipmentColumn {
  display: flex;
  flex-direction: column;
}

.driver-delivered-status {
  border-radius: 16px;
  background-color: #00c614;
}

.driver-delivered-status p {
  font-size: 12px;
  color: #fff;
  margin: 5px 10px;
}

.driver-outstanding-status {
  border-radius: 16px;
  background-color: #DC8612;
}

.driver-outstanding-status p {
  font-size: 12px;
  color: #fff;
  margin: 5px 10px;
}

.originName {
  color: #1a6783;
  font-size: 12;
  font-weight: 300;
}

.button-order {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.button-order button {
  background-color: #12a3dc;
  border: none;
  border-radius: 10px;
  width: 157px;
  height: 34px;
  color: #fff;
}

/* Mobile dashboard */

.logout-button {
  display: none;
}

.itemList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.itemList p {
  margin-top: 16px;
}

.itemList li {
  gap: 32px;
  display: flex;
  align-items: center;
}

.itemList-container {
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  background-color: #E8F6FC;
  width: 274px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.itemList span {
  height: 55px;
  width: 0.5px;
  background-color: #000;
}

.ItemListBox {
  display: flex;
  flex-direction: column;
}

.ItemListBox h3 {
  margin-top: px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.ItemListBox p {
  height: 15px;
  margin: 0;
  font-size: 10px;
  font-weight: 300px;
  color: #000;
}

.itemBox1 {
  display: flex;
  flex-direction: column;
}

.itemBox2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.itemBox2 div {
  font-weight: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  height: 20px;
  margin-bottom: 10px;
}

.border-cam {
  position: relative;
  cursor: pointer;
  border-Radius: 50%;
  width: 35px;
  height: 35px;
  background-Color: #12a3dc;
  display: flex;
  justify-Content: center;
  align-Items: center;
  bottom: -120px;
  left: -50px;
  z-index: 1;
  border: none;
  padding: 0;
}

.border-cam:focus {
  outline: none;
}

.myprofile-button,
.myorg-button,
.createorg-button,
.joinorg-button,
.logout-button {
  display: none;
}

.mobileStyle {
  font-size: 1.5rem !important;
}

.btn.mobileStyle {
  height: 40px;
}

.social-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.social-buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 170px;
}

.btn-google {
  background-color: #db4437;
  color: white;
}

.btn-facebook {
  background-color: #3b5998;
  color: white;
}

.social-buttons button .icon {
  margin-right: 5px;
}

.social-buttons button:hover {
  opacity: 0.8;
}

.icon-lg {
  font-size: 16px;
}

.txt-login {
  font-family: "Calibri", sans-serif !important;
  color: grey;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
}

.txt-login::before,
.txt-login::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid grey;
  margin: 0 10px;
}

.bind-buttons {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  gap: 12px;
}

.bind-buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 230px;
}

.bind-buttons button:hover {
  opacity: 0.8;
}

.btn-google-bind {
  background-color: #db4437;
  color: white;
}

.btn-facebook-bind {
  background-color: #3b5998;
  color: white;
}

.fa-button {
  background: none;
  border: 0;
  cursor: pointer;
}

.fa-button:focus {
  outline: none;
  border: none;
}

.fa-button:hover {
  color: #12a3dc;
}

.switch-form {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-form input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-form {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider-form:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider-form {
  background-color: #2196F3;
}

input:focus+.slider-form {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider-form:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.incvoice-container-filter {
  display: flex;
  align-items: center;
  gap: 180px;
  justify-content: center;
}

.calendar-invoice img {
  width: 55px;
  height: 55px;
  cursor: pointer;
}

.txt-filter-invoice {
  margin-left: 10px;
}

.txt-filter-invoice span {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 500;
}

.tgl-filter-invoice {
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 600;
  margin-top: 3px;
  cursor: pointer;
}

.line-filter-invoice {
  border-right: 2px solid #a9a9a9;
  height: 80px;
  margin: 0 5px;
  margin-top: 15px;
}

.modal-container-filter {
  display: flex;
  align-items: center;
  gap: 45px;
  justify-content: center;
}

.contain-filter {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.txt-filter {
  margin-left: 10px;
}

.txt-filter span {
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 500;
}

.tgl-filter {
  font-size: 11px;
  font-family: "Roboto";
  font-weight: 600;
  margin-top: 3px;
  color: black;
}

.line-filter {
  border-right: 1px solid #a9a9a9;
  height: 15px;
  margin: 0 5px;
  margin-top: 15px;
}

.line-filter-cash {
  border-right: 2px solid #e7e7e7;
  height: 100px;
  text-align: center;
}

.modal-btn {
  border-radius: 13px;
  height: 35px;
  align-self: end;
  justify-content: center;
  padding: 3px 20px;
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
}

.circle-icon {
  width: 40px;
  height: 50px;
  border-radius: 10%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-cash {
  display: flex;
  align-items: center;
}

.txt-cash-span {
  margin-left: 7px;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 700;
  color: #000;
  position: relative;
  align-items: center;
}

.txt-cash-span p {
  margin-top: 5px;
  font-size: 10px;
  align-items: center;
  font-weight: 400;
}

.txt-cash-span2 {
  margin-left: 7px;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 700;
  color: #000;
  position: relative;
  margin-right: 10px;
}

.txt-cash-span2 p {
  margin-top: 5px;
  font-size: 10px;
  align-items: center;
  font-weight: 400;
}

.txt-minus {
  margin-left: 10px;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 500;
  color: #FF0000;
  margin-bottom: 40px;
  position: relative;
  top: -8px;
}

.text-status {
  font-size: 11px;
  font-family: "Roboto";
  font-weight: 500;
  color: white;
  margin-left: auto;
  padding: 5px 10px;
  background-color: #3e425d;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
}

.info-box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 4px 8px;
  margin-top: -10px;
  transition: all 0.3s;
}

.info-box:hover {
  background-color: #e9ecef;
  transform: scale(1.05);
}

.amount {
  font-size: 13px;
  font-weight: 600;
  color: #212529;
}


/* Mobile */

.Add-Circle {
  position: sticky;
  bottom: 100px;
  margin-left: 320px;
  height: 52px;
  width: 52px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.5s;
}

.AddCircle1 {
  height: 25px;
  width: 3px;
  background-color: var(--light-color);
  position: relative;
  right: -13.5px;
  transition: all 0.5s;
}

.AddCircle2 {
  height: 3px;
  width: 26px;
  background-color: var(--light-color);
  transition: all 0.5s;
}

.Add-Circle.show {
  background-color: var(--danger-color);

  .AddCircle1 {
    rotate: 45deg;
  }

  .AddCircle2 {
    rotate: 45deg;
  }
}

.mobile-action-card {
  padding: 0;
  background-color: var(--primary-color);
  position: fixed;
  bottom: 180px;
  right: 30px;
  width: 168px;
  height: 62px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}



.mobile-action-card span {
  color: var(--light-color);
  font-size: 16px;
  text-align: right;
  margin: auto 0;
  margin-left: 10px;
}

.mobile-action-card-link {
  display: flex;
  gap: 18px;
}

.mobile-action-icon {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background-color: var(--light-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-action-icon svg {
  height: 20px;
  width: 20px;
  color: var(--primary-color);
}


.NoData h1 {
  font-size: 26px;
  font-weight: 700;
  color: black;
  margin-top: 30px;
}

.NoData h5 {
  font-size: 18px;
  font-weight: 400;
  color: black;
}

.NoData {
  margin-top: 150px;
  display: flex;
  flex-direction: column;

}

/* mobile */

.containerCard {
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color);
  margin-top: 20px;
  padding: 0px 0 0px 23px;
  z-index: 100;
}

.card-text.show {
  background-color: #ECF9FF;
}

.card-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-text-full {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.module-card-body {
  background-color: var(--light-color);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0px;
}

.card-text-header {
  margin-right: 150px;
  display: flex;
  gap: 20px;
}

.card-text-header img {
  height: 24px;
  width: 24px;
}

.fleet-number-card {
  font-size: 18px;
  font-weight: 700;
  font-family: "Roboto";
  color: #000;
}

.status-card {
  font-size: 12px;
  font-weight: 500;
  font-family: "Roboto";
}

.line-card {
  width: 300px;
  height: 0.45px;
  background-color: rgba(0, 0, 0, 0.3);
}

.title-card {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  font-family: "Roboto";
  margin-top: 10px;
  position: relative;
  left: -50px;
  margin-bottom: 20px;
  width: 200px;
}

.card-text-body {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -30px;
  gap: 5px;
}

.text-body {
  display: flex;
  align-self: start;
  gap: 30px;
  margin-top: 5px !important;
}

.text-body h6 {
  width: 100px;
  /* font-size: 14px; */
  margin: 0 !important;

}

.text-body-p {
  width: 100px;
  font-weight: 600;
  color: #000;
  margin: 0 !important;
}



.card-checkbox {
  height: 21px;
  width: 21px;
}

.card-checkbox input[type="checkbox"] {
  height: 21px;
  width: 21px;
}

.isInformation {
  width: 90%;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--light-color);
  position: fixed;
  top: 20%;
  height: 70%;
  overflow: auto;
  border-radius: 20px;
  padding: 30px;
  z-index: 998 !important;
}

.isInformation::-webkit-scrollbar {
  display: none;
  /* This hides the scroll bar in WebKit browsers */
}

.buttonThree {
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  background-color: #12A3DC;
}

.pagination button {
  position: relative;
  background-color: white;
  border: none;
  color: #12A3DC;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.pagination button:disabled {
  background-color: #A9A9A9;
  cursor: not-allowed;
}

.pagination .prev-button::before,
.pagination .next-button::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
}

.status-column {
  pointer-events: none;
}

.custom-button {
  pointer-events: auto;
  color: #FFFFFF;
  background-color: #dc3545 !important;
  border-radius: 10px;
  font-size: 14px;
  padding: 6px 14px;
  border: none !important;
  text-align: center;
  cursor: pointer;
}

.label-stat {
  align-items: center !important;
}

.payment-page {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.package-box {
  width: 45%;
  background-color: #132847;
  color: white;
  border-radius: 25px;
  overflow: hidden;
  border: 2px solid #06A2DC;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.package-header {
  background-color: #D3F0FB;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.package-header img {
  height: 40px;
  width: 40px;
  margin-bottom: 5px;
}

.package-header h2 {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
}

.package-details {
  padding: 10px;
  margin-top: 5px;
}

.price {
  display: flex;
  align-items: baseline;
  margin: 5px 0;
  justify-content: center;
  margin-top: 20px;
}

.currency {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  color: #a1a9b5;
  position: relative;
  top: -1.5em;
  margin-right: 5px;
}

.amount-pay {
  font-size: 40px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.period {
  font-size: 16px;
  margin-left: 5px;
  font-family: 'Poppins', sans-serif;
  color: #a1a9b5;
  margin-bottom: 20px;
}

.package-details ul {
  list-style: none;
  padding: 0;
  margin: 10px 25px;
  font-family: 'Poppins', sans-serif;
  margin-top: 30px;
  margin-bottom: 10px;
}

.package-details ul li {
  margin: 3px 0;
  font-size: 18x;
  display: flex;
  align-items: center;
}

.package-details ul li svg {
  margin-right: 15px;
  color: white;
  font-size: 16px;
}

.package-details ul li strong {
  font-weight: bold;
  color: white;
  margin-right: 5px;
}

.payment-info {
  width: 50%;
}

.payment-info h2 {
  margin-bottom: 10px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
}

.payment-details {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: black;
  font-family: 'Poppins', sans-serif;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.detail-item strong {
  font-family: 'Poppins', sans-serif;
  margin-right: 5px;
}

.payment-method label {
  font-size: 18px;
  color: black;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.payment-method {
  margin: 10px 0;
}

.payment-options {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
}

.payment-option.selected {
  background-color: #DCF1FA;
  color: white;
}

.select-pay {
  font-size: 20px;
  color: black;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-top: 10px;
}

.payment-box {
  width: 90px;
  height: 80px;
  border: 2px solid #06A2DC;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  color: #06A2DC;
  border-radius: 10px;
  margin-right: 20px;
}

.payment-box .payment-icon {
  font-size: 35px;
  margin-bottom: 5px;
}

.payment-box.selected {
  background-color: #06A2DC;
  color: white;
}

.payment-box.selected .payment-icon {
  color: white;
}

.payment-options-details {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.payment-option {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  width: 90px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.payment-box span {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}

.payment-option:hover {
  transform: scale(1.05);
}

.pay-now-button {
  background-color: #06A2DC;
  color: white;
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.img-plane {
  margin-top: 20px;
  width: 100px !important;
  height: 90px !important;
}

.payment-option img {
  height: auto;
}

.payment-image.ovo {
  max-width: 75%;
  margin-top: 3px;
}

.payment-image.dana {
  max-width: 90%;
  margin-top: 8px;
}

.payment-image.gopay {
  max-width: 95%;
  margin-top: 5px;
}

.payment-image.shopee {
  max-width: 90%;
}

.payment-image.link {
  max-width: 65%;
}

.payment-image.bca {
  max-width: 80%;
  margin-top: 13px;
}

.payment-image.mandiri {
  max-width: 100%;
  margin-top: 10px;
}

.payment-image.bni {
  max-width: 85%;
  margin-top: 10px;
}

.payment-image.bri {
  max-width: 100%;
}

.payment-image.visa {
  max-width: 75%;
  margin-top: 13px;
}

.payment-image.mastercard {
  max-width: 70%;
}

.payment-image.jcb {
  max-width: 55%;
}

.payment-option:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.no-payment {
  text-align: center;
  padding: 20px;
}

.no-payment-image {
  width: 250px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.no-payment-text {
  font-weight: 800;
  color: #000;
  font-size: 22px;
  margin-top: 10px;
}

.mobile-number-input {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mobile-number-input label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

.mobile-number-input input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.mobile-number-input input:focus {
  border-color: #007bff;
}

.payment-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 600px;
  width: 100%;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* .modal-header {
  border-bottom: 1px solid #dee2e6;
  padding: 1rem;
}

.modal-body1 {
  padding: 1.5rem;
} */

#invoiceContent h3 {
  margin-bottom: 1rem;
}

/* #virtualAccount {
  font-weight: bold;
  font-family: monospace;
  font-size: 1.25rem;
} */

.card-modal {
  margin-bottom: 1rem;
  border: 1px solid #ddd;
}

.card-header-modal {
  background-color: #f8f9fa;
  padding: 0.75rem 1.25rem;
}

.card-body-modal {
  padding: 1.25rem;
}

.card-header-modal .btn-link {
  font-weight: bold;
  font-size: 1rem;
  text-align: left;
  color: #333;
}

.card-header-modal .btn-primary:focus {
  text-decoration: none;
}

.collapse.show {
  background-color: #fff;
}

.payment-detailss {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 0;
  background-color: #f1f1f1;
  padding: 0 14px;
  border-radius: 0 0 10px 10px;
}

.payment-detailss.open {
  max-height: 200px;
  padding: 14px;
}

.card-header-modal {
  background-color: #12A3DC;
  color: white;
  padding: 15px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.card-header-modal .btn-link {
  color: white;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header-modal:hover {
  background-color: #0d8cbf;
}

.card-header-modal .fa-chevron-down {
  transition: transform 0.3s ease;
}

.collapse.show .card-header-modal {
  background-color: #0d8cbf;
}

.collapse.show .fa-chevron-down {
  transform: rotate(180deg);
}

.card-body-modal {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease;
}

.form-control-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.4rem 1rem !important;
  height: calc(1em + 1.7rem) !important;
}

.button-modal-credit {
  background-color: #12A3DC;
  border-radius: 10px;
  border-color: #12A3DC;
  width: 40%;
  height: 40px;
  font-size: 20px !important;
  font-weight: bold;
}

.collapse.show .card-body-modal {
  max-height: 500px;
}

.spinner-load {
  display: inline-block;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container-status {
  width: 100%;
  height: 100px;
  border: 1px solid #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.btn-refresh {
  height: 30px !important;
  margin-top: 5px !important;
}

.pay-now-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  opacity: 0.6;
}

.success-container {
  position: relative;
  text-align: center;
  padding: 50px 20px;
  overflow-y: auto;
  height: 100vh;
}

.background-half {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45%;
  background-color: #D3F0FB;
}

.mascot-image {
  position: relative;
  margin-top: 40px;
}

.mascot-image img {
  max-width: 350px;
  display: block;
  margin: 0 auto;
}

.success-text {
  /* font-weight: 800; */
  color: black;
  /* margin-top: 260px; */
}

.success-text h1 {
  margin-bottom: 10px;
  margin-top: 40px;
  font-weight: 800;
}

.success-text p {
  font-size: 26px;
  margin-bottom: 30px;
}

.order-details {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.detail-item-success {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  color: black;
}

.total-section {
  background-color: #132847;
  color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 26px;
  margin-top: 20px;
}

.back-home-button {
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  background: var(--primary-color);
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: var(--light-color);
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.85);
}

.payment-logo {
  width: 100%;
  max-width: 100px;
}

.mobile-bulk-card {
  padding: 0;
  background-color: var(--primary-color);
  width: 168px;
  height: 62px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.mobile-div-card {
  background-color: None;
  position: fixed;
  bottom: 180px;
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mobile-bulk-span {
  color: var(--light-color);
  font-size: 16px;
  text-align: right;
  margin: auto 0;
  margin-left: 5px;
}

.font-current {
  font-size: 18px;
}

.payment-container {
  margin-left: 120px;
}

.label-section {
  flex: 0 0 200px;
}

.separator {
  flex: 0 0 10px;
  text-align: center;
}

.content-section {
  flex: 1;
}

.copy-btn {
  color: white;
  padding: 4px 9px;
  font-size: 13px;
  position: relative;
  top: -10px;
  left: 20px;
}

.nowrap {
  white-space: nowrap;
}

.text-primary {
  font-size: 25px;
}

.text-primary1 {
  font-size: 23px;
  color: #007bff;
  font-weight: 550;
}

.download-icon {
  font-size: 20px;
}

.bg-storageExist{
  background-color: #fec564;
}