@media only screen and (max-width: 769px) {
  .notfound .notfound-404 h1 {
    font-size: 156px;
    top: 10%;
  }
  .notfound h2 {
    font-size: 30px;
  }
  .notfound p {
    font-size: 12px;
  }
  .notfound a {
    font-size: 10px;
    padding: 10px 38px;
  }

  .navbar {
    height: 60px !important;
  }

  .navbar-left,
  .navbar-brand {
    width: 100% !important;
    text-align: center;
  }

  .navbar-brand img {
    height: 45px !important;
  }

  .navbar-right {
    display: none !important;
  }
  .sidemenu-opener {
    width: 45px;
    height: 45px;
    padding-top: 15px;
  }

  .login-card {
    box-shadow: none;
    width: 100%;
  }

  .wide-screen {
    display: none !important;
  }
  .narrow-screen {
    display: flex !important;
    width: 100%;
    flex-flow: row;
  }

  .content {
    padding: 20px 20px 80px 20px;
  }
  .container {
    max-width: 769px;
  }

  .content>.card {
    border: 0px;
  }

  .card-header {
    border: 0px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .btn-module {
    border-radius: 5px;
    min-height: 38px;
    padding: 0.2rem 1rem;
  }

  .bottom-nav {
    display: flex;
  }

  /* .app-content {
    height: calc(100vh - 100px);
  } */

  .order-status-item {
    margin-right: 15px;
    border: 0px;
  }
  .order-status-text {
    display: none !important;
  }

  .achievement-content-left {
    padding: 10px 10px;
  }
  .achievement-content-left .mc-fa,
  .achievement-content-right .mc-fa {
    display: none;
  }
  .achievement-content-right div {
    margin-right: 20px;
  }

  .fleet-order-content-item div {
    padding: 5px 15px;
  }
  .fleet-order-content-item div .mc-fa {
    position: absolute;
    top: 25px;
    right: 15px;
    padding: 6px;
    border-radius: 100%;
    text-align: center;
    width: 24px;
    height: 24px;
  }

  .order-content-item div {
    padding: 10px 15px;
    margin-bottom: 10px;
  }
  .order-content-total div {
    margin-top: 0px;
  }

  .invite-button {
      display: block !important;
      margin-top: 230px !important;
      margin-left: -100px !important;
      text-align: center !important;
  }

  .img-responsif{
    margin-left: -25px !important;
    margin-right: 50px !important;
    width: 160px !important;
  }

  .circular-image-container{
    width: 150px !important;
    height: 150px !important;
    margin-left: -28px !important;
  }

  .name-container{
    margin-left: -30px !important;
  }
  
  .bind-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-license {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
    gap: 20px !important;
    font-size: 20px !important;
    text-align: start !important;
    margin: 0 auto !important;
    margin-left: -20px !important;
}
  .container-license > div {
    margin-bottom: 10px !important;
  }

  .container-license button {
    margin-top: 5px !important;
  }

  .container-detail-license {
    grid-template-columns: 1fr !important;
  }
  .price-total {
    margin-left: 10px !important;
  }
  .detail-txt {
    font-size:16px !important;
    margin-top: 20px !important;
  }

  .save-button-license {
    margin-top: 20px !important;
    width: 100px !important;
    height: 30px !important;
  }
  .monthly-payment h2 {
    font-size: 24px !important;
  }
  .total-amount {
    font-size: 35px !important;
  }
  .container-license button {
    border-width: 0.5px !important;
    padding: 4px 9px !important;
    width: 30px !important;
  }
  .subTitle-license {
    font-weight: 600 !important;
    font-size: 1.2rem !important;
    width: 95% !important;
  }
  .container-type-license {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .container-type-license h2 {
    font-size: 20px !important;
  }
  .type-license {
    gap: 10px !important;
  }

  .subTitle-type {
    font-size: 11px !important;
  }

  .icon-invoice {
    font-size: 12px !important;
    margin-left: 10px !important;
  }
  .icon-invoice div {
    font-size: 12px !important;
  }
  .status-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .status-box span {
    font-size: 12px !important;
    margin-left: 0 !important;
    margin-bottom: 5px !important;
  }

  .status-box div {
    width: 85px !important;
    height: 25px !important;
    margin-left: 0 !important;
    margin-bottom: 20px !important;
    font-size: 12px !important;
  }
  .status-box-list span {
    font-size: 12px !important;
    margin-left: 0 !important;
  }

  .status-box-list div {
    width: 80px !important;
    height: 25px !important;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
    font-size: 12px !important;
  }

  .status-box2 button {
    width: 86px !important;
    height: 30px !important;
    margin-left: 0 !important;
    font-size: 12px !important;
    border-radius: 10px !important;
  }

  .status-box2 {
    margin-bottom: -120px !important;
    align-self: flex-start !important;
  }
  .icon-bookmark {
    width: 30px !important;
    height: 30px !important;
  }
  .circle-invoice {
    width: 30px !important;
    height: 30px !important;
  }

  .icon-paid {
    width: 100px !important;
    height: 100px !important;
  }

  .incvoice-container-filter {
    gap: 25px !important;  
  }
  .calendar-invoice img {
    width: 35px !important;
    height: 35px !important;
  }
  .txt-filter-invoice span {
    font-size: 12px !important;
  }
  .tgl-filter-invoice {
    font-size: 14px !important;
  }
  .line-filter-invoice {
    border-right: 1px solid #a9a9a9 !important;
    height: 40px !important;
  }
  #invoiceContent {
    max-width: 100%;
    padding: 10px;
  }
  .subTitle-container {
    -webkit-overflow-scrolling: touch;
  }

  .payment-page {
    flex-direction: column !important;
    align-items: center !important;
    padding: 0px !important;
  }
  .package-box {
    width: 90% !important;
    margin-bottom: 20px !important;
  }
  .payment-info {
    width: 90% !important;
  }
  .payment-options {
    flex-direction: column !important;
    align-items: center !important;
  }
  .payment-box {
    width: 100% !important;
    height: auto !important;
    margin: 10px 0 !important;
  }
  .img-plane {
    margin-top: 20px !important;
    width: 75px !important;
    height: 70px !important;
  }
  .package-header h2 {
    font-size: 20px !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
  .price {
    margin-top: 5px !important;
  }
  .amount-pay {
    font-size: 26px !important;
  }
  .currency {
    font-size: 8px !important;
    position: relative;
    top: -2.5em;
    margin-right: 10px;
  }
  .period {
    font-size: 8px !important;
    margin-left: 5px;
    margin-bottom: 10px !important;
  }
  .package-details ul {
    margin-top: 25px !important;
    margin: 10px 5px !important;
  }
  .package-details ul li {
    margin-top: 15px !important;
    font-size: 16px !important;
  }
  .package-details ul li svg {
    font-size: 12px !important;
    margin-right: 15px !important;
  }
  .payment-options-details {
    flex-direction: column !important;
    align-items: center !important;
    margin-top: 0px !important;
  }
  .payment-info h2 {
    margin-top: 10px !important;
    font-size: 18px !important;
    text-align: center;
  }
  .payment-details {
    font-size: 13px !important;
  }
  .payment-container {
    margin-left: 0 !important;
  }
  .label-section {
      width: 100% !important;
      margin-bottom: 5px !important;
      flex: 0 0 80px !important;
  }
  .content-section {
      width: 100% !important;
  }
  .copy-btn {
    font-size: 6px !important;
    padding: 2px 7px !important;
  }
  .text-primary {
    font-size: 16px !important;
  }
  .text-primary1 {
    font-size: 13px !important;
  }
  .detail-item strong {
    margin-right: 0px !important;
  }
  .payment-method label {
    font-size: 18px !important;
  }
  .payment-option {
    width: 40% !important;
    margin: 10px 0 !important;
  }
  .payment-options {
    margin-top: 0px !important;
  }
  .select-pay{
    font-size: 18px !important;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
  }
  .payment-box {
    width: 130px !important;
    height: 80px !important;
    margin-right: 0px !important;
  }
  .payment-box span {
    font-size: 12px !important;
  }
  .payment-box .payment-icon {
    font-size: 40px !important;
    margin-bottom: 10px;
  }
  .payment-image {
    max-width: 80% !important;
    margin-top: 10px !important;
  }
  .pay-now-button {
    width: 100%;
    height: 40px;
    font-size: 18px !important;
  }
  .monthly-details {
    display: flex !important;
    flex-direction: column !important;
  }
  .invoice-info {
    font-size: 15px !important;
  }
  .invoice-date {
    font-size: 15px !important;
  }
  .font-current {
    font-size: 12px !important;
  }
  .container-status {
    height: auto !important;
    padding: 20px !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .modal-status h2{
    font-size: 30px !important;
  }
  .payment-logo {
    margin-top: 10px !important;
    margin-bottom: 30px !important;
  }
  .btn-refresh {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 663px) {
  .list-card-header {
    flex-direction: column;
  }
  .left-flex, .right-flex {
    margin-bottom: .5rem!important;
    width: 100%;
  }
  .search-control {
    width: 100%;
  }
  .myprofile-button, .myorg-button, .createorg-button, .joinorg-button, .logout-button {
    display: block;
  }
  
}

@media only screen and (max-width: 1020px){
  .type-license {
    gap: 15px !important;    
  }
  .subTitle-license {
    font-weight: 600 !important;
    font-size: 1.2rem !important;
    width: 95% !important;
  }
  .incvoice-container-filter {
    gap: 80px;
  }
}


@media only screen and (max-width: 763px){
  .form-group{
    font-size: 12px;
  }
}
